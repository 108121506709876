<template>
  <v-container>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
        <div><v-checkbox class="my-1 ml-2" v-model="item.is_reserve" label="Резервный"></v-checkbox></div>
        
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row ref="form">
            <v-col class="pt-0 pb-0" cols="4">
              <v-text-field
                ref="name"
                v-model="item.name"
                :rules="[() => !!item.name]"
                dense
                autocomplete="off"
                label="Название:"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <v-text-field
                ref="short_name"
                v-model="item.short_name"
                dense
                autocomplete="off"
                label="Краткое Название (4 символа):"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <v-text-field
                ref="place_number"
                v-model.number="item.place_number"
                :rules="[() => !!item.place_number]"
                dense
                label="Основных мест:"
                type="number"
                autocomplete="off"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <v-select
                v-model="item.category_type"
                :items="caterogyTypes"
                label="Тип категории:"
                dense
              ></v-select>
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <v-text-field
                ref="additional_place"
                v-model.number="item.additional_place"
                :rules="[v => Number.isInteger(Number(v))]"
                dense
                label="Доп. место:"
                type="number"
                :min="0"
                autocomplete="off"
                @input="resetAdditionalPLace"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <div v-if="item.additional_place" class="pl-3 mb-5">
                <v-row align="center">
                  Детей:
                  <v-btn @click="changeChild(-1)" color="red" icon
                    ><v-icon>mdi-minus</v-icon></v-btn
                  >
                  {{ item.child_count }}
                  <v-btn @click="changeChild(1)" color="primary" icon
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </v-row>
                <v-row align="center">
                  Взрослых:
                  <v-btn @click="changeAdult(-1)" color="red" icon
                    ><v-icon>mdi-minus</v-icon></v-btn
                  >
                  {{ item.adult_count }}
                  <v-btn @click="changeAdult(1)" color="primary" icon
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </v-row>
              </div>
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <v-text-field
                v-if="item.additional_place"
                v-model.number="item.child_age"
                dense
                label="Возраст ребенка:"
                type="number"
                :min="0"
                autocomplete="off"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="3">
              <v-text-field
                ref="room_name"
                v-model="item.room_name"
                :rules="[() => !!item.room_name]"
                dense
                label="Название номеров:"
                autocomplete="off"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="3">
              <v-text-field
                :disabled="$route.params.id != undefined"
                ref="room_number"
                v-model.number="item.room_number"
                :rules="[() => !!item.room_number]"
                dense
                label="Количество номеров для создания:"
                type="number"
                autocomplete="off"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="3">
              <v-text-field
                :disabled="$route.params.id != undefined"
                ref="koika_count"
                v-model.number="item.koika_count"
                :rules="[() => !!item.koika_count]"
                dense
                label="Количество коек для создания:"
                type="number"
                autocomplete="off"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="3">
              <v-text-field
                :disabled="$route.params.id != undefined"
                ref="numbering"
                v-model.number="item.numbering"
                :rules="[() => !!item.numbering]"
                dense
                label="Нумерация с:"
                type="number"
                autocomplete="off"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="8">
              <v-textarea
                ref="description"
                v-model="item.description"
                outlined
                name="input-7-4"
                label="Описание:"
                :rules="[() => !!item.description]"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <v-text-field
                @focus="menu = true"
                label="Период работы:"
                style="margin: 0; padding: 0"
                v-model="workingPeriod"
              >
              </v-text-field>
              <v-select :items="category_types" v-model="item.category_type">
              </v-select>
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <v-row align="center">
                <v-text-field
                  v-model="search"
                  label="Добавить тэги"
                  autocomplete="off"
                ></v-text-field>
                <v-btn small class="ml-4" @click="addTags">Добавить</v-btn>
              </v-row>
            </v-col>
            <v-col class="pt-0 pb-0" cols="8">
              <v-row>
                <v-btn
                  color="warning"
                  v-for="(tag, index) in item.tag_list"
                  :key="index"
                  @click="deleteTag(index)"
                >
                  {{ tag.text }}
                  <v-icon class="ml-2" color="red">mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </v-col>

            <v-col class="pt-0 pb-0" cols="4">
              <v-file-input
                v-model="singleImage"
                label="Главное фото"
                prepend-icon="mdi-camera"
                show-size
                chips
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="8">
              <v-row align="center">
                <v-file-input
                  ref="multiImages"
                  v-model="multiImages"
                  label="Фото"
                  prepend-icon="mdi-camera"
                  multiple
                  counter
                  show-size
                  chips
                />
              </v-row>
            </v-col>
            <v-col v-if="$route.params.id" class="pt-0 pb-0" cols="12">
              <v-row class="mb-5" align="center" v-if="item.main_image">
                <img :src="item.main_image" height="300" />
              </v-row>
              <div v-for="(image, index) in item.image_list" :key="index">
                <v-row class="mb-4" align="center">
                  <img :src="image.image" height="200" />
                  <v-btn icon @click="deleteImage(image.id, index)">
                    <v-icon small color="red darken-2"> mdi-delete </v-icon>
                  </v-btn>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="close"> Отменить </v-btn>
        <v-btn color="primary" text @click="save" :disabled="disabledBtn">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="menu" width="500">
      <v-card class="py-7 px-3">
        <v-row align="center" justify="center"> Выберите период работы </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="item.start_day"
              label="C:"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select v-model="item.start_month" :items="months"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="item.end_day"
              label="По:"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select v-model="item.end_month" :items="months"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col></v-col>
          <v-col align="end" justify="end">
            <v-btn color="primary" @click="menu = false" outlined
              >Ок</v-btn
            ></v-col
          >
        </v-row>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import toast from "../../../plugins/toast";
import user from "../../../store/auth";
import { CategoriesApiUrls } from "@/services/accommodationRequests/categories.api.js";

export default {
  name: "Category",
  data() {
    return {
      disabledBtn: false,
      caterogyTypes: [
        {
          text: "По дням и часам",
          value: "both"
        },
        {
          text: "По дням",
          value: "only_day"
        },
        {
          text: "По часам",
          value: "only_time"
        }
      ],
      radios: [],
      ages: [],
      child_age: null,
      menu: false,
      category_types: [
        {
          text: "Только по суткам",
          value: "only_day"
        },
        {
          text: "Только по часам",
          value: "only_time"
        },
        {
          text: "По суткам и по часам",
          value: "both"
        }
      ],
      months: [
        {
          text: "Январь",
          value: 1
        },
        {
          text: "Февраль",
          value: 2
        },
        {
          text: "Март",
          value: 3
        },
        {
          text: "Апрель",
          value: 4
        },
        {
          text: "Май",
          value: 5
        },
        {
          text: "Июнь",
          value: 6
        },
        {
          text: "Июль",
          value: 7
        },
        {
          text: "Август",
          value: 8
        },
        {
          text: "Сентябрь",
          value: 9
        },
        {
          text: "Октябрь",
          value: 10
        },
        {
          text: "Ноябрь",
          value: 11
        },
        {
          text: "Декабрь",
          value: 12
        }
      ],
      addCountAge: [
        {
          text: "Взрослый",
          value: 1
        },
        {
          text: "Детский",
          value: 2
        }
      ],
      additionalCount: [],
      overlay: false,
      search: "",
      select: [],
      multiImages: [],
      singleImage: null,
      item: {
        adult_count: 0,
        category_type: "only_day",
        child_count: 0,
        child_age: 0,
        start_month: 6,
        start_day: 1,
        end_day: 31,
        end_month: 8,
        ages: [],
        id: null,
        name: "",
        place_number: 1,
        additional_place: 0,
        room_name: "",
        company: Number(user.state.company_id),
        room_number: 1,
        koika_count: 0,
        numbering: 1,
        description: "",
        is_reserve: false,
        tag_list: [],
        short_name: "",
      },
      loading: false
    };
  },
  validations: {
    item: {
      name: { required },
      place_number: { required },
      additional_place: { required },
      room_name: { required },
      room_number: { required },
      koika_count: { required },
      numbering: { required },
      description: { required }
    }
  },
  computed: {
    formTitle() {
      return this.$route.params.id ? "Редактировать" : "Создать";
    },
    workingPeriod() {
      let start_day = Number(this.item.start_day).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      let start_month = this.item.start_month.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      let end_day = Number(this.item.end_day).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      let end_month = this.item.end_month.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      return `${start_day}.${start_month} - ${end_day}.${end_month}`;
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.item = await CategoriesApiUrls.getCategory(this.$route.params.id);
      this.select = this.item.tag_list;
    }
  },
  methods: {
    resetAdditionalPLace() {
      this.item.child_count = 0;
      this.item.adult_count = 0;
    },
    changeAdult(numb) {
      if (
        this.item.additional_place >= this.item.adult_count + Number(numb) &&
        this.item.adult_count + this.item.child_count + Number(numb) <=
          this.item.additional_place
      ) {
        if (this.item.adult_count + Number(numb) >= 0) {
          this.item.adult_count += Number(numb);
        }
      } else {
        toast.error("Не достаточно мест");
      }
    },
    changeChild(numb) {
      if (
        this.item.additional_place >= this.item.child_count + Number(numb) &&
        this.item.adult_count + this.item.child_count + Number(numb) <=
          this.item.additional_place
      ) {
        if (this.item.child_count + Number(numb) >= 0) {
          this.item.child_count += Number(numb);
        }
      } else {
        toast.error("Не достаточно мест");
      }
    },
    async deleteImage(id, index) {
      this.overlay = true;
      try {
        await CategoriesApiUrls.deleteImage(id);
        toast.success("Успешно удалено!");
        this.item.image_list.splice(index, 1);
      } finally {
        this.overlay = false;
      }
    },
    async deleteTag(idx) {
      if (this.item.tag_list[idx].id) {
        await CategoriesApiUrls.deleteTag(this.item.tag_list[idx].id);
        toast.success("Успешно удален!");
      }
      this.item.tag_list.splice(idx, 1);
    },
    async addTags() {
      if (this.item.tag_list.filter(el => el.text == this.search).length > 0) {
        toast.error("Тэги не должны повторяться");
        return;
      }
      let res = {};
      if (this.$route.params.id) {
        res = await CategoriesApiUrls.createTag({
          text: this.search,
          category: this.$route.params.id
        });
        toast.success("Успешно добавлено!");
      }
      this.item.tag_list.push({
        text: this.search,
        category: this.$route.params.id,
        id: res.id
      });
      this.search = "";
    },
    async save() {
      if (
        this.item.additional_place !=
        this.item.adult_count + this.item.child_count
      ) {
        toast.error("Количество доп. мест не совпадает");
        return;
      }
      this.overlay = true;
      let response;
      if (this.$route.params.id) {
        this.item.tag_list = this.select;
        response = await CategoriesApiUrls.setCategory(
          this.$route.params.id,
          this.item
        );
        toast.success("Успешно изменено!");
      } else {
        response = await CategoriesApiUrls.createCategory(this.item);
        toast.success("Успешно создано!");
      }

      this.disabledBtn = true;
      var formData = new FormData();
      formData.append("category", response.id);

      if (this.singleImage) {
        formData.append(
          "main_image",
          this.singleImage,
          this.singleImage.fileName
        );
        await CategoriesApiUrls.addMainImage(formData, response.id);
      }
      if (this.multiImages.length) {
        for (var i = 0; i < this.multiImages.length; i++) {
          var formData_1 = new FormData();
          formData_1.append("category", response.id);

          formData_1.append(
            "image",
            this.multiImages[i],
            this.multiImages[i].fileName
          );
          await CategoriesApiUrls.addImage(formData_1);
        }
      }
      if (this.item.tag_list.length && !this.$route.params.id) {
        this.item.tag_list.forEach(e => {
          e.category = response.id;
        });
        for (let i = 0; i < this.item.tag_list.length; i++) {
          await CategoriesApiUrls.createTag(this.item.tag_list[i]);
        }
      }
      this.overlay = false;
      this.close();
      this.disabledBtn = false;
    },
    close() {
      this.$router.push("/categories");
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.single_tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
}
.grid_list {
  display: grid;
  grid-template-columns: 300px 600px;
  grid-column-gap: 50px;
}
</style>
